<template>
  <div class="media">
    <MediaUploader />
    <MediaList />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import MediaList from "@/components/media/MediaList";
import MediaUploader from "@/components/media/MediaUploader";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "PractitionerMedia",
  components: { MediaUploader, MediaList },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useMediaStore, ["files"]),
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchFiles", "emptyFiles"]),
    ...mapActions(useMediaStore, ["setUid"]),
  },
  async mounted() {
    await this.setUid(this.$route.params.patientId);
  },
};
</script>

<style scoped></style>
